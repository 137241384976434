export default [
    {
        path: '/',
        name: 'pc',
        component: () => import('@/views/index.vue'),
        // redirect: '/',
        children: [
            {
                path: '/',
                component: () => import('@/layout/index.vue'),
                redirect: '/',
                children: [
                    {
                        path: '',
                        component: () => import('@/views/pc/home/index.vue')
                    },
                    {
                        path: 'AIPhotos',
                        component: () => import('@/views/pc/aiPhoto/index.vue'),
                        meta: {
                            description: 'ai photo generator',
                            keywords: 'ai photo generator, ai photo editor, ai photo enhancer,ai photo generator free'
                            
                        }
                    },
                    {
                        path: 'AIAvatars',
                        component: () => import('@/views/pc/aiPhoto/aiAvatar.vue'),
                        meta: {
                            description: 'ai avatar generator',
                            keywords: 'ai avatar generator, ai photo editor, ai photo enhancer,ai photo generator free'
                        }
                    },
                    {
                        path: 'Blogs',
                        component: () => import('@/views/pc/blog/index.vue')
                    },
                    {
                        path: 'Blogs/Detail',
                        component: () => import('@/views/pc/blog/detail.vue')
                    },
                    {
                        path: 'MyGallery',
                        component: () => import('@/views/pc/gallery/index.vue')
                    },
                    {
                        path: 'cookiepolicy',
                        component: () => import('@/views/pc/cookiePolicy/index.vue')
                    },
                    {
                        path: '/:catchAll(.*)',
                        component: () => import('@/views/pc/notFound.vue'),
                        redirect: '/',
                    }
                ]
            },
            // {
            //     path: 'login',
            //     children: [
            //         {
            //             path: '',
            //             component: () => import('@/views/pc/login/index.vue'),
            //         },

            //     ]
            // },
                {
                path: 'CheckEmail',
                component: () => import('@/views/pc/login/checkEmail.vue')
            },
            // {
            //     path: 'EmailLogin',
            //     component: () => import('@/views/pc/login/emailLogin.vue')
            // },
            {
                path: 'transfer',
                component: () => import('@/views/transfer.vue')

            },
        ]
    },

]

