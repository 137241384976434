import type { App } from 'vue'
const rightClickDisabled = {
    mounted (el: { addEventListener: (arg0: string, arg1: (event: Event) => void) => void; tagName: string; draggable: boolean; querySelectorAll: (arg0: string) => any[]; }) {
        el.addEventListener('contextmenu', (event: Event) => {
            event.preventDefault();
        });

        if (el.tagName === 'IMG') {
            el.draggable = false;
        } else {
            el.querySelectorAll('img').forEach((img: { draggable: boolean; }) => {
                img.draggable = false;
            });
        }
    }
};
// 暂时合并到上面的指令
const disableDrag = {
    mounted (el: { tagName: string; draggable: boolean; querySelectorAll: (arg0: string) => any[]; }) {
        if (el.tagName === 'IMG') {
            el.draggable = false;
        } else {
            el.querySelectorAll('img').forEach(img => {
                img.draggable = false;
            });
        }
    }
}
export const setupDirectives = (app: App<Element>) => {
    app.directive('right-click-disabled', rightClickDisabled);
    app.directive('disable-drag', disableDrag);
}
