<template>
  <svg :class="[className, 'svg-icon']" :style="{ width: props.width ? width : '', height: _height ? _height : '' }">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps({
  name: { type: String, require: true },
  width: { type: String, },
  height: { type: String },
  className: { type: String },
});
const _height = computed(() => {
  return props.height ? props.height : props.width;
});
const iconName = computed(() => {
  return `#${props.name}`;
});
</script>

<style lang="scss" scoped>
.svg-icon {
  // background-color: transparent;
}
</style>