import type { CartItem, PhotoItem, ThemeDetail } from "@/types/home";
import { defineStore } from "pinia";
interface GlobalState {
    /** 展示aiPhoto的遮罩层 */
    showAiPhotoMarker: boolean
    /** 购物车 */
    cartVisible: boolean
    /** 购物车已存在时出弹窗提示 */
    cartDoubleVisible: boolean
    /** 充值余额 */
    rechargeVisible: boolean
    /** 充值会员 */
    selectProVisible: boolean
    /** 购物车列表 */
    cartList: CartItem[]
    /** 计划的入口 */
    planEntry: 'home' | 'selectPro'
    // /** 区分 动漫类 写实类 */
    // photoType: 1 | 0
    /** 画廊首次堵塞 展示弹窗 */
    isFirstBlock: boolean
    // 删除成功
    deleteSuccessVisible: boolean
    // gallery的数量是否展示
    showGalleryCount: boolean
    // 用户支付后要把铃铛的弹窗打开
    showBellPop: Boolean
    /** 邀请成功弹窗 邀请人和被邀请人公用一个 */
    inviteSuccessVisible: Boolean
    // 展示隐私政策
    showCookiePolicy: Boolean
    // 生成照片后检测照片是否弹窗涉黄，涉黄后退费弹窗，仅弹窗一次
    errorImgVisible: Boolean
    errorIsFirst: Boolean
    // 支付成功弹窗
    rechargeSuccessVisible: Boolean
    // 会员开通成功弹窗
    ProSuccessVisible: Boolean
    // 会员降级成功弹窗
    ProDowngradeSuccessVisible: Boolean
    // 会员升级成功弹窗
    ProUpgradeSuccessVisible: Boolean
    /** 微信支付宝 充值 */
    payChinaVisible: Boolean
    /** 用户首次登录弹窗 点击后永久消失 */
    firstLoginVisible: Boolean
    // 是否首次登录
    is_create: Boolean
    // 是否是升级计划 false代表降级
    isUpgrade: Boolean
    // 登录弹窗
    loginVisible: Boolean
    // 登录后修改个人信息弹窗
    loginEditVisible: Boolean
    // 免费模板首次体验完成 弹窗问卷
    freeCollectVisible: Boolean
    // 免费模版体验提交成功
    collectSuccessVisible: Boolean
    // 问卷审核完成奖励弹窗
    collectAwardSuccessVisible: Boolean
    guideVisible: Boolean
    // 引导第几部
    guideStep: number
    cartInfo: ThemeDetail
    // 支付成功后铃铛震动两秒
    bellShake: Boolean
}
export const useGlobalStore = defineStore({
    id: "r_design_global",
    state: () => ({
        showAiPhotoMarker: false,
        cartVisible: false,
        rechargeVisible: false,
        cartDoubleVisible: false,
        selectProVisible: false,
        cartList: [] as CartItem[],
        planEntry: 'home',
        isFirstBlock: true,
        deleteSuccessVisible: false,
        showGalleryCount: true,
        showBellPop: false,
        inviteSuccessVisible: false,
        // 未登录和一年内点击过都不展示
        showCookiePolicy: false,
        errorImgVisible: false,
        errorIsFirst: true,
        rechargeSuccessVisible: false,
        ProSuccessVisible: false,
        payChinaVisible: false,
        firstLoginVisible: false,
        is_create: false,
        isUpgrade: true,
        ProDowngradeSuccessVisible: false,
        ProUpgradeSuccessVisible: false,
        loginVisible: false,
        loginEditVisible: false,
        freeCollectVisible: false,
        guideVisible: false,
        collectSuccessVisible: false,
        collectAwardSuccessVisible: false,
        bellShake: false,
        guideStep: 1,
        cartInfo: {} as ThemeDetail,
    }),
    getters: {},
    actions: {
        setGlobalState (key: keyof GlobalState, val: any) {
            this.$patch({ [key]: val });
        }
    },
});
