import { defineStore } from 'pinia'
import { store } from '../index'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import es from 'element-plus/es/locale/lang/es'
import ja from 'element-plus/es/locale/lang/ja'
import { useStorage } from '@/hooks/useStorage'
import { isChina } from '@/utils/is';

export interface Language {
  el: Recordable
  name: string
}
interface LocaleDropdownType {
  lang: LocaleType
  name?: string
  elLocale?: Language
}
const { getStorage, setStorage } = useStorage('localStorage')

const elLocaleMap: any = {
  en: en,
  es: es,
  ja: ja,
  'zh-CN': zhCn,
}
interface LocaleState {
  currentLocale: LocaleDropdownType
  localeMap: LocaleDropdownType[]
}
const getLang = (): any => {
  // const language =  navigator.language
  // if (language.includes('en')) return 'en'
  // if (language.includes('es')) return 'es'
  // if (language.includes('ja')) return 'ja'
  // if (language.includes('zh')) return 'zh-CN'
  const language = isChina() ? 'zh-CN' : 'en'
  return language
}
export const useLocaleStore = defineStore('locales', {
  state: (): LocaleState => {
    return {
      currentLocale: {
        lang: getLang() || getStorage('lang') || 'en',
        elLocale: getLang() || elLocaleMap[getStorage('lang') || 'en']
      },
      // 多语言
      localeMap: [
        {
          lang: 'en',
          name: 'English'
        },
        {
          lang: 'es',
          name: '西班牙语'
        },
        {
          lang: 'ja',
          name: '日语'
        },
        {
          lang: 'zh-CN',
          name: '汉语'
        }
      ]
    }
  },
  getters: {
    getCurrentLocale (): LocaleDropdownType {
      return this.currentLocale
    },
    getLocaleMap (): LocaleDropdownType[] {
      return this.localeMap
    }
  },
  actions: {
    setCurrentLocale (localeMap: LocaleDropdownType) {
      // this.locale = Object.assign(this.locale, localeMap)
      this.currentLocale.lang = localeMap?.lang
      this.currentLocale.elLocale = elLocaleMap[localeMap?.lang]
      setStorage('lang', localeMap?.lang)
    }
  }
})

export const useLocaleStoreWithOut = () => {
  return useLocaleStore(store)
}
