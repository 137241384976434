import { isMobile } from "./is"

(function flexible (window, document) {
    var docEl = document.documentElement
    var dpr = window.devicePixelRatio || 1
    function setBodyFontSize () {
        if (document.body) {
            document.body.style.fontSize = (12 * dpr) + 'px'
        }
        else {
            document.addEventListener('DOMContentLoaded', setBodyFontSize)
        }
    }
    setBodyFontSize();

    function setRemUnit () {
        if (docEl.clientWidth > 800) return
        var rem = docEl.clientWidth / 10
        docEl.style.fontSize = rem + 'px'
    }
    setRemUnit()

    window.addEventListener('resize', setRemUnit)
    window.addEventListener('pageshow', function (e) {
        if (e.persisted) {
            setRemUnit()
        }
    })
    if (dpr >= 2) {
        var fakeBody = document.createElement('body')
        var testElement = document.createElement('div')
        testElement.style.border = '.5px solid transparent'
        fakeBody.appendChild(testElement)
        docEl.appendChild(fakeBody)
        if (testElement.offsetHeight === 1) {
            docEl.classList.add('hairlines')
        }
        docEl.removeChild(fakeBody)
    }
    if (docEl.clientWidth > 2000) {
        docEl.style.fontSize = 19.2 + 'px'
    } else if (docEl.clientWidth > 1300) {
        docEl.style.fontSize = 14 + 'px'
    } else if (docEl.clientWidth > 800) {
        if (isMobile()) return
        docEl.style.fontSize = docEl.clientWidth / 75 + 'px'
    }
}(window, document))
