<!--
 * @Description  : 
 * @Author       : WangXy
 * @Date         : 2024-04-15 15:39:28
 * @LastEditTime : 2024-07-03 18:48:44
 * @LastEditors  : WangXy
 * @FilePath     : \web\src\App.vue
-->

<template>

  <RouterView />
</template>
<script setup lang="ts">
import { onBeforeUnmount, onMounted } from 'vue';
import _ from 'lodash'
import { deviceStore } from './stores/modules/device';
import { useRouter } from 'vue-router';
import pc from '@/router/pc'
import mobile from '@/router/mobile'
import { isMobile } from './utils/is';

const router = useRouter()
const { changeDevice } = deviceStore()
let fontSize: number;

/**
 * @description:  设置路由 pc、mobile
 * @author: WangXy
 * @param {*} removeType 移除旧路由
 * @param {*} route 添加新路由
 * @return {*}
 */
const setRouter = (removeType: string, route: any) => {
  if (router.hasRoute(removeType)) {
    router.removeRoute(removeType)
    setTimeout(() => {
      const fullPath = router.currentRoute.value.fullPath
      if (hasRoute(route, fullPath)) {
        router.replace(fullPath)
      } else {
        router.replace('/')
      }
    }, 0)
  }
  router.addRoute(route[0])
}
// 判断是否存在 path 为 pathToFind 的路由项
const hasRoute = (routes: any[], pathToFind: string) => {
  const hasPath = routes.some((route) => {
    if (route.path === pathToFind) {
      return true;
    }
    if (route.children) {
      return route.children.some((childRoute: { path: string; }) => childRoute.path === pathToFind);
    }
    return false;
  });
  return hasPath;
}
const resizeChange = () => {
  const clientWidth = document.documentElement.clientWidth
  // 设计稿尺寸 pc端 1440  ipad 834 mobile 430   
  // pc ipad公用一套布局，两套样式维护  插件 postcsspxtoviewport
  // mobile 独立布局、样式  插件 postCssPxToRem
  // 注释手机端
  // changeDevice(isMobile() ? 'mobile' : 'pc')
  // if (isMobile()) {
  //   setRouter('pc', mobile)
  // } else {
  //   setRouter('mobile', pc)
  // }
  setRouter('mobile', pc)
  changeDevice('pc')
  if (clientWidth >= 2000) {
    fontSize = 19.2
  } else if (clientWidth > 1300) {
    fontSize = 14
  } else {
    // if (isMobile()) return
    fontSize = clientWidth / 75
  }
  import('@/assets/style/pc-styles.scss')
  document.documentElement.style.fontSize = fontSize + 'px'
}
resizeChange()
const throttleResize = _.throttle(resizeChange, 0);
window.addEventListener('resize', throttleResize, false)


onBeforeUnmount(() => {
  window.removeEventListener('resize', throttleResize, false)
})

</script>
