import './assets/main.css'

import { createApp } from 'vue'

import 'uno.css' // focus code
import App from './App.vue'
import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import './assets/common.scss'
import './utils/resize.ts'
import 'virtual:svg-icons-register';
import R_Ui from './components'
import 'animate.css';
// 引入状态管理
import { setupStore } from '@/stores/index'
// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n/index'
// 路由
import  router,{ setupRouter } from './router'
// 自定义指令
import { setupDirectives } from './directives'
import Lazyload from "vue3-lazyload";
import * as Sentry from "@sentry/vue";
import { isProduction } from './utils/is'

// 创建实例
const setupAll = async () => {
    const app = createApp(App)
    if (isProduction()) {
        Sentry.init({
            app,
            environment: import.meta.env.MODE,
            integrations: [
                Sentry.browserTracingIntegration({router}),
                Sentry.replayIntegration({
                    networkDetailAllowUrls:["https://api.1px.ai",window.location.origin],
                    maskAllText:false,
                    blockAllMedia:false,
                }),
                Sentry.httpClientIntegration()
            ],
            dsn: "https://e046362663d08bd3078032d4ecbe817c@o4507503446589440.ingest.de.sentry.io/4507503494234192",
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            // trackComponents:true,
            sendDefaultPii:true
        });
    }
    await setupI18n(app)
    setupStore(app)
    setupRouter(app)
    setupDirectives(app)
    app.use(R_Ui)
    app.use(ElementPlus)
    app.mount('#app')
    //注册插件
    app.use(Lazyload, {
        // loading: "@/assets/image/gallery_img01.png",//可以指定加载中的图像
        // error: "@/assets/images/err.png",//可以指定加载失败的图像
    });
}

setupAll()