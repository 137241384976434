import { createRouter, createWebHistory } from 'vue-router'
import type { App } from 'vue'
import pc from './pc'
import mobile from './mobile'
import { isMobile } from '@/utils/is'
import { useGlobalStore } from '@/stores/modules/global';
// 注释手机端
// const routes:any =isMobile()?mobile:pc
const routes:any =pc
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  strict: false,
  scrollBehavior: () => ({ left: 0, top: 0 })
})
router.beforeEach((to: any, from, next) => {
  const dom = document.querySelectorAll('.container-main')[0]
  if (dom) {
    dom.scrollTop = 0;
    const globalStore = useGlobalStore()
    globalStore.setGlobalState('showAiPhotoMarker', false)

  }
  // if (to.meta.description) {
  //   let metaDescription: any = document.querySelector('meta[name="description"]');
  //   if (metaDescription) {
  //     metaDescription.setAttribute('content', to.meta.description);
  //   } else {
  //     metaDescription = document.createElement('meta');
  //     metaDescription.name = 'description';
  //     metaDescription.content = to.meta.description;
  //     document.head.appendChild(metaDescription);
  //   }
  // }

  // if (to.meta.keywords) {
  //   let metaKeywords: any = document.querySelector('meta[name="keywords"]');
  //   if (metaKeywords) {
  //     metaKeywords.setAttribute('content', to.meta.keywords);
  //   } else {
  //     metaKeywords = document.createElement('meta');
  //     metaKeywords.name = 'keywords';
  //     metaKeywords.content = to.meta.keywords;
  //     document.head.appendChild(metaKeywords);
  //   }
  // }
  next();
});
export const setupRouter = (app: App<Element>) => {
  app.use(router)
}
export default router
