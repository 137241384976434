import type { Component, App } from 'vue'
import SvgIcon from './svgIcon/index.vue'

const components: {
  [propName: string]: Component
} = {
  SvgIcon
}
const installComponents: any = (app: App) => {
  for (const key in components) {
    app.component(key, components[key])
  }
}
const install: any = (app: any, router?: any) => {
  installComponents(app)
}
export {
  SvgIcon
}
export default {
  install,
}
